import React, { useRef, useState } from 'react';

import Stepper from '@keyvaluesystems/react-stepper';
import classNames from 'classnames';
import { FieldErrors, FieldPath, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactForm } from '../../../Contact/Contact';
import { Step } from '../../../Form/components/Step/Step';
import './GenericConfigurator.css';

export interface GenericConfiguratorProps<T extends ContactForm> {
  form: UseFormReturn<T>,
  steps: StepNode<T>[],
  activeStep: number,
  className: string,
  getSummary: (form: UseFormReturn<T>) => React.ReactNode
  onSubmit: () => void,
  onInvalid: () => void
  setSteps: (steps: StepNode<T>[]) => void,
  setActiveStep: (activeStep: number) => void
}

export interface StepRenderProps<T extends ContactForm> {
  active: boolean,
  form: UseFormReturn<T>,
  steps: StepNode<T>[],
  stepIndex: number,
  activeStep: number,
  gotoStep: (i: number) => () => void
}

export interface StepNode<T extends ContactForm> {
  id: number,
  getStepLabel: () => string,
  stepLabel?: string,
  completed: boolean,
  completedTest: (form: UseFormReturn<T>) => boolean,
  formFields: FieldPath<T>[],
  valid: boolean,
  visited?: boolean,
  active?: boolean,
  isSummary?: boolean,
  renderer: (props: StepRenderProps<T>) => React.ReactNode
}

export function GenericConfigurator<T extends ContactForm>(props: GenericConfiguratorProps<T>) {
  const { i18n } = useTranslation();
  if (!props.steps.find(s => s.isSummary)) {
    props.steps.push({
      id: Number.MAX_SAFE_INTEGER,
      getStepLabel: () => i18n.t("configurator.summary"),
      completed: false,
      completedTest: () => props.steps[props.steps.length - 1].valid,
      formFields: ["name" as any, "email", "phone", "address", "content"],
      valid: false,
      visited: false,
      isSummary: true,
      renderer: (stepProps: StepRenderProps<T>) => {
        return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("configurator.summary")} className={'summary-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.submitCount > 0 && !stepProps.form.formState.isValid ? "Formularz zawiera błędy walidacji. Proszę uzupełnić brakujące pola." : ""}>
          <div className='container-h'>
            <div className='contact-form' style={{ flexGrow: 1 }}>
              <label>
                {stepProps.form.formState.errors.name?.message ? <span className='error'>{stepProps.form.formState.errors.name?.message as string}</span> : ""}
                <input {...stepProps.form.register("name" as any, { required: i18n.t("form.validation.required") })} type="text" />
                <span>{i18n.t("contact.form.name")}</span>
              </label>
              <label>
                {stepProps.form.formState.errors.email?.message ? <span className='error'>{stepProps.form.formState.errors.email?.message as string}</span> : ""}
                <input {...stepProps.form.register("email" as any, { required: i18n.t("form.validation.required") })} type="email" />
                <span>E-mail</span>
              </label>
              <label>
                {stepProps.form.formState.errors.phone?.message ? <span className='error'>{stepProps.form.formState.errors.phone?.message as string}</span> : ""}
                <input {...stepProps.form.register("phone" as any, { required: i18n.t("form.validation.required") })} type="tel" />
                <span>{i18n.t("contact.form.phone")}</span>
              </label>
              <label>
                {stepProps.form.formState.errors.address?.message ? <span className='error'>{stepProps.form.formState.errors.address?.message as string}</span> : ""}
                <input {...stepProps.form.register("address" as any, { required: i18n.t("form.validation.required") })} type="text" />
                <span>{i18n.t("contact.form.address")}</span>
              </label>
              <label className='span-2'>
                {stepProps.form.formState.errors.content?.message ? <span className='error'>{stepProps.form.formState.errors.content?.message as string}</span> : ""}
                <textarea {...stepProps.form.register("content" as any)} style={{ height: "10em" }} maxLength={3000} />
                <span>{i18n.t("contact.form.content")}</span>
              </label>
              <span className='span-2'>{i18n.t("form.notOffer")}</span>
            </div>
            {props.getSummary(props.form)}
          </div>
          <button className="large orange glow" style={{ marginTop: "1em" }} type="submit">{i18n.t("cctv.send")}</button>
        </Step>
      }
    });
  }

  const containerRef = useRef<HTMLDivElement>(null);


  const triggerValidate = (steps: StepNode<T>[]) => {
    const promises = steps
      .filter(step => step.visited)
      .map(step => {
        const stepFields = step.formFields
          .map(f => props.form.trigger(f))

        if (stepFields.length === 0) {
          return Promise.resolve();
        }
        return Promise.all(stepFields).then(results => {
          // step.valid = validate(step, props.form.formState.errors);
          step.valid = (results.find(e => !e)) === undefined;
        })
      })

    Promise.all(promises).then(() => {
      const newSteps = [...steps];
      props.setSteps(newSteps);
    })
  }

  const validate = (step: StepNode<T>, e: FieldErrors<T>) => {
    return !step.formFields.find(f => {
      const errors = (e as any)[f];
      return errors || errors?.root || errors?.length > 0;
    })
  }
  props.steps[props.activeStep].active = true;
  props.steps[props.activeStep].visited = true;
  const gotoStep = (goToIndex: number) => () => {
    if (props.activeStep === goToIndex) {
      return;
    }

    props.steps[props.activeStep].active = false;
    props.steps[props.activeStep].visited = true;
    props.steps[goToIndex].active = true;
    for (let i = 0; i < goToIndex; ++i) {
      props.steps[i].visited = true;
    }
    props.steps.forEach(s => s.completed = !!s.completedTest?.(props.form))
    const newSteps = [...props.steps];
    props.setSteps(newSteps);
    props.setActiveStep(goToIndex);
    triggerValidate(newSteps);
    setTimeout(() => {
      containerRef.current?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    }, 20);
  }

  props.steps.forEach(s => s.stepLabel = s.getStepLabel()) // i18n workaround for @keyvaluesystems/react-stepper

  return <div className={`main-content GenericConfigurator ${props.className}`}>
    <Stepper
      steps={props.steps}
      currentStepIndex={props.activeStep}
      labelPosition={"top"}
      onStepClick={((step: StepNode<T>, stepIndex: number) => gotoStep(stepIndex)())}
      renderNode={(step: StepNode<T>, stepIndex: number) => {
        const classnames = classNames([
          'step-node',
          { 'step-error': step.visited && !step.valid },
          { 'step-active': step.active },
          { 'step-complete': step.completed && step.valid },
          { 'step-visited': step.visited },
        ])
        return <div className={classnames} key={stepIndex + ' ' + step.valid}>
          <div className='step-node-text'>
            {stepIndex + 1}
          </div>
        </div>
      }}
      styles={{
        Node: () => ({
          opacity: 1,
          cursor: "pointer",
          borderRadius: 0,
          transform: "rotate(45deg)"
        })
      }}
    />
    <div className='content' style={{ padding: 0 }} ref={containerRef}>
      <h3>{i18n.t("cctv.title")}</h3>
      <form onSubmit={props.form.handleSubmit(props.onSubmit, (e) => {
        console.log(e);
        props.steps.forEach(s => s.valid = validate(s, e))
        const newSteps = [...props.steps];
        props.setSteps(newSteps);
        props.onInvalid();
      })}>
        {props.steps.map((s, i) => <React.Fragment key={s.id}>{s.renderer({
          active: i === props.activeStep,
          stepIndex: i,
          activeStep: props.activeStep,
          steps: props.steps,
          form: props.form,
          gotoStep: gotoStep
        })}</React.Fragment>)}
      </form>
    </div>
  </div>
}
