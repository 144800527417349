import { GoogleReCaptchaProvider } from '@google-recaptcha/react';
import i18next from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from "react-i18next";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import { About } from './components/About/About';
import { Certificates } from './components/Certificates/Certificates';
import { Configurator } from './components/Configurator/Configurator';
import { Cctv } from './components/Configurator/components/Cctv/Cctv';
import { Contact } from './components/Contact/Contact';
import { Start } from './components/Start/Start';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createOptions, productsById } from './components/Configurator/components/products';
import BigNumber from 'bignumber.js';

i18next
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .on('failedLoading', (lng, ns, msg) => console.error(msg));
i18next.init({
  load: 'languageOnly',
  lng: 'pl',
  ns: '',
  backend: {
    loadPath: "/translation/{{lng}}.json",
  },
});

BigNumber.config({
  FORMAT: {
    decimalSeparator: ",",
    groupSeparator: " ",
    groupSize: 3,
  },
  DECIMAL_PLACES: 2,
  ROUNDING_MODE: BigNumber.ROUND_HALF_UP
});



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Start />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "certificates",
        element: <Certificates />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "configurator",
        element: <Configurator />,
      },
      {
        path: "cctv",
        element: <Cctv />,
        loader: () => {
          return Promise.all([
            fetch("/configurator/cctv.json").then(r => r.text()),
            fetch("/configurator/products.json").then(r => r.text())
          ]).then(resp => {
            return {
              options: createOptions(resp[0], resp[1]),
              products: productsById(resp[1])
            }
          })
        }
      },
      {
        path: "alarm",
        element: <div>Alarm</div>,
      },
      {
        path: "videophone",
        element: <div>Wideofony IP</div>,
      },
      {
        path: "smarthome",
        element: <div>Smart home</div>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleReCaptchaProvider
    type="v3"
    siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
  >
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
