import React, { CSSProperties } from 'react';

import './Title.css';

export interface TitleProps {
  text?: string;
  style?: CSSProperties
}

export function Title(props: TitleProps) {
  return <div style={props.style} className='Title'>
    <div className='mark'></div>
    <div className='title-text'>{props.text}</div>
    <div className='line-container'><hr /></div>
  </div>
}
