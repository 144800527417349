import React from 'react';

import './Step.css';

export interface StepProps {
  className?: string,
  title?: string,
  error?: string,
  children?: any
}

export function Step(props: StepProps) {
  return <div className={'Step ' + (props.className ?? '')}>
    <div className='header'>
      <h4 className='title' >{props.title}</h4>
    </div>
    <div className='body'>
      {props.children}
      {props.error ? (<span className='error'>{props.error}</span>) : ""}
    </div>
  </div>
}
