import { useReducer, useState } from 'react';
import { ConfigItem } from '../../../Form/components/ConfigItem/ConfigItem';

import BigNumber from 'bignumber.js';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { ContactForm } from '../../../Contact/Contact';
import { NumberField } from '../../../Form/components/NumberField/NumberField';
import { Step, StepProps } from '../../../Form/components/Step/Step';
import { Title } from '../../../Title/Title';
import { GenericConfigurator, StepNode, StepRenderProps } from '../GenericConfigurator/GenericConfigurator';
import { Camera, CAMERA_INSTALLATION_ANALYTICS_ID, CAMERA_INSTALLATION_ID, ConfigElement, DataStorage, NVR_INSTALLATION_ID, NVR_INSTALLATION_INTEGRATION_ID, ProductsById, RACK_INSTALLATION_ID, Recorder, PoeSwitch as Switch } from '../products';
import './Cctv.css';

export interface CctvConfigOptions {
  analyticsRecorder: Recorder,
  noAnalyticsRecorder: Recorder,
  switch: Switch,
  integration: ConfigElement,
  cameras: Camera[],
  installationMethod: ConfigElement[],
  dataStorage: DataStorage[],
  installationStorage: ConfigElement[]
}

export interface CctvConfigForm extends ContactForm {
  recorder: string,
  integrations: string,
  cameras: { camera: Camera, count?: number }[],
  installationMethod: string,
  dataStorage: { dataStorage: DataStorage, count?: number }[],
  installationStorage: string,
  cabling: string
}

export interface CctvConfig {
  recorder: Recorder,
  integrations: number,
  cameras: { camera: Camera, count: number }[],
  installationMethod: ConfigElement,
  dataStorage: { dataStorage: DataStorage, count: number }[],
  installationStorage: ConfigElement,
  cabling: string
}

export enum Cabling {
  EMPTY = "",
  NO_CABLING = "no_cabling",
  BAD_CABLING = "bad_cabling",
  GOOD_CABLING = "good_cabling"
}

const img = "https://b2b.eltrox.pl/media/catalog/product/cache/0/small_image/450x450/9df78eab33525d08d6e5fb8d27136e95/0/_/0_2_3_10586_1_8149_1_13171_1_2762_1_1532_1_460_1_42_1_6773_1_13_1_5514_1_2398.jpg";

const MORE_THAN_2_INTEGRATIONS: ConfigElement = {
  id: -1,
  name: {
    pl: "Więcej niż 2 integracje",
    en: "More than 2 integrations"
  },
  img: img,
  priceNet: 450,
  priceGross: 450,
  computedNet: new BigNumber(450),
  computedGross: new BigNumber(450),
}

const _2_OR_FEWER_INTEGRATIONS: ConfigElement = {
  id: -2,
  name: {
    pl: "2 lub mniej integracji",
    en: "2 or fewer integrations"
  },
  img: img,
  priceNet: 0,
  priceGross: 0,
  computedNet: new BigNumber(0),
  computedGross: new BigNumber(0),
}

function getFormatQualityActivity(
  videoFormat: "mpeg" | "h264" | "h265" | "h265plus",
  quality: "low" | "medium" | "high",
  activityLevel: "low" | "medium" | "high"
): number {
  let fca: number = 0;
  if (videoFormat === "mpeg") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 16;
      }
      if (activityLevel === "medium") {
        fca = 16;
      }
      if (activityLevel === "low") {
        fca = 16;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 25;
      }
      if (activityLevel === "medium") {
        fca = 25;
      }
      if (activityLevel === "low") {
        fca = 25;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 31;
      }
      if (activityLevel === "medium") {
        fca = 31;
      }
      if (activityLevel === "low") {
        fca = 31;
      }
    }
    return fca;
  }
  if (videoFormat === "h264") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 98;
      }
      if (activityLevel === "medium") {
        fca = 102;
      }
      if (activityLevel === "low") {
        fca = 105;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 160;
      }
      if (activityLevel === "medium") {
        fca = 173;
      }
      if (activityLevel === "low") {
        fca = 181;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 220;
      }
      if (activityLevel === "medium") {
        fca = 242;
      }
      if (activityLevel === "low") {
        fca = 260;
      }
    }
    return fca;
  }
  if (videoFormat === "h265") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 260;
      }
      if (activityLevel === "medium") {
        fca = 272;
      }
      if (activityLevel === "low") {
        fca = 282;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 480;
      }
      if (activityLevel === "medium") {
        fca = 510;
      }
      if (activityLevel === "low") {
        fca = 536;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 620;
      }
      if (activityLevel === "medium") {
        fca = 670;
      }
      if (activityLevel === "low") {
        fca = 710;
      }
    }
    return fca;
  }
  if (videoFormat === "h265plus") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 335;
      }
      if (activityLevel === "medium") {
        fca = 351;
      }
      if (activityLevel === "low") {
        fca = 364;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 590;
      }
      if (activityLevel === "medium") {
        fca = 621;
      }
      if (activityLevel === "low") {
        fca = 643;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 776;
      }
      if (activityLevel === "medium") {
        fca = 834;
      }
      if (activityLevel === "low") {
        fca = 833;
      }
    }
    return fca;
  }
  return 0;
}

function getBandwidth(
  numberOfCameras: number,
  pixelsPerFrame: number,
  fps: number,
  fqa: number
) {
  const bitsPerPixel = pixelsPerFrame > 8294399 ? 30 : 16;
  // bytes per second
  return pixelsPerFrame * (bitsPerPixel / 8) * fps * (numberOfCameras / fqa);
}

function getBytes(
  numberOfCameras: number,
  daysToStore: number,
  pixelsPerFrame: number,
  fps: number,
  hoursPerDay: number,
  fqa: number
) {
  // const pixels = RESOLUTIONS[resolutionName]
  const hoursPerDayFactor = hoursPerDay / 24;
  const seconds = 86400 * daysToStore * hoursPerDayFactor;
  const bandwidth = getBandwidth(numberOfCameras, pixelsPerFrame, fps, fqa);
  return Math.ceil(seconds * bandwidth);
}

function getSeconds(
  dataStorageBytes: number,
  numberOfCameras: number,
  pixelsPerFrame: number,
  fps: number,
  fqa: number
) {
  const bandwidth = getBandwidth(numberOfCameras, pixelsPerFrame, fps, fqa);
  return Math.floor(dataStorageBytes / bandwidth);
}

function formatBytes(bytes: number) {
  if (bytes < 1_000) {
    return bytes + "B";
  } else if (bytes < 1_000_000) {
    return (bytes / 1_000).toFixed(2) + "KB";
  } else if (bytes < 1_000_000_000) {
    return (bytes / 1_000_000).toFixed(2) + "MB";
  } else if (bytes < 1_000_000_000_000) {
    return (bytes / 1_000_000_000).toFixed(2) + "GB";
  } else if (bytes < 1_000_000_000_000_000) {
    return (bytes / 1_000_000_000_000).toFixed(2) + "TB";
  } else if (bytes < 1_000_000_000_000_000_000) {
    return (bytes / 1_000_000_000_000_000).toFixed(2) + "PB";
  } else {
    return "";
  }
}

function formatSeconds(seconds: number) {
  const days = Math.floor(seconds / 86400);
  seconds -= (days * 86400);
  const hours = Math.floor(seconds / 3600);
  seconds -= (hours * 3600);
  const minutes = Math.floor(seconds / 60);
  seconds -= (minutes * 60);

  if (days === 0) {
    if (hours === 0) {
      if (minutes === 0) {
        return seconds + "s";
      } else {
        return minutes + "min " + seconds + "s";
      }
    } else {
      return hours + "h " + minutes + "min " + seconds + "s";
    }
  } else {
    return days + "d  " + hours + "h " + minutes + "min " + seconds + "s";
  }
}

const cablingDict = [
  {
    key: Cabling.EMPTY, value: {
      pl: "Wybierz",
      en: "Select"
    }
  },
  {
    key: Cabling.NO_CABLING, value: {
      pl: "Nie mam jeszcze żadnej instalacji lub moja instalacja nie ma jeszcze zainstalowanego okablowania",
      en: "I don't have any installation yet or my installation doesn't have the cabling installed yet"
    }
  },
  {
    key: Cabling.BAD_CABLING, value: {
      pl: "Moja instalacja posiada okablowanie ale jest innego rodzaju niż skrętka UTP lub jest niższej klasy niż 5e",
      en: "My installation has cabling but it is of a different type than UTP twisted pair or is of a lower class than 5e"
    }
  },
  {
    key: Cabling.GOOD_CABLING, value: {
      pl: "Moja instalacja posiada okablowanie rodzaju UTP klasy 5e lub lepszej",
      en: "My installation has UTP class 5e cabling or better"
    }
  },
];


function estimateStoredRecordingLength(
  cameras: CctvConfigForm["cameras"],
  dataStorage: CctvConfigForm["dataStorage"],
) {
  cameras = cameras?.filter(e => (e.count ?? 0) > 0);
  dataStorage = dataStorage?.filter(e => (e.count ?? 0) > 0);
  if (cameras?.length && dataStorage?.length) {
    const cameraCount = cameras.map(c => c.count!).reduce((a, b) => a + b, 0)
    const pixelsPerFrame = cameras.map(e => e.camera.pixelsPerFrame * e.count!).reduce((acc, x) => acc + x) / cameraCount;
    const totalStorageBytes = dataStorage.map(s => s.count! * s.dataStorage.capacityBytes).reduce((a, b) => a + b, 0);
    const seconds = getSeconds(
      totalStorageBytes,
      cameraCount,
      pixelsPerFrame,
      15,
      getFormatQualityActivity(
        'h264',
        'low',
        "high"
      )
    );

    return formatSeconds(seconds);
  } else {
    return ""
  }
}

function mapFormToModel(f: CctvConfigForm, options: CctvConfigOptions) {
  if (!f) {
    return {};
  }

  const recorderId = f.recorder && parseInt(f.recorder)
  const integrationsId = f.integrations && parseInt(f.integrations)

  const cameras = f.cameras.filter(e => (e.count ?? 0) > 0) as { camera: Camera, count: number }[];
  const dataStorage = f.dataStorage.filter(e => (e.count ?? 0) > 0) as { dataStorage: DataStorage, count: number }[];
  const installationId = f.installationMethod && parseInt(f.installationMethod)
  const storageId = f.installationStorage && parseInt(f.installationStorage)

  const newConfig: Partial<CctvConfig> = {};
  newConfig.recorder = [options.analyticsRecorder, options.noAnalyticsRecorder].find(r => r.id === recorderId);
  newConfig.integrations = integrationsId as number;
  newConfig.cameras = cameras;
  newConfig.installationMethod = options.installationMethod.find(i => i.id === installationId);
  newConfig.dataStorage = dataStorage;
  newConfig.installationStorage = options.installationStorage.find(i => i.id === storageId);
  newConfig.cabling = f.cabling ?? Cabling.NO_CABLING;
  return newConfig;
}

function CameraStep(props: { stepProps: StepRenderProps<CctvConfigForm>, options: CctvConfigOptions }) {
  const stepProps = props.stepProps;
  const { i18n } = useTranslation();
  const cameraFieldArray = useFieldArray({
    control: stepProps.form.control,
    name: "cameras",
    rules: {
      validate: {
        atLeastOneCamera: e => e.filter(i => (i.count ?? 0) > 0).length > 0 ? true : i18n.t("cctv.step3.atLeastOne"),
        notMoreThanCamera: e => {
          const maxCameras = mapFormToModel(stepProps.form.getValues(), props.options).recorder?.maxCameras ?? 16;
          const selectedCameraCount = e.filter(i => (i.count ?? 0) > 0).map(e => e.count!).reduce((a, b) => a + b, 0);
          return selectedCameraCount > maxCameras ? (i18n.t("cctv.step3.notMoreThan") + maxCameras) : true;
        }
      }
    }
  });

  return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step3.label")} className={'camera-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.errors.cameras?.root?.message}>
    {cameraFieldArray.fields.map((c, index) => {
      const error = stepProps.form.formState.errors.cameras?.[index]?.message;
      return <ConfigItem key={c.id} configElement={c.camera}>
        {error ? <span className='error'>{error}</span> : ""}
        <NumberField setValue={(name, value) => {
          stepProps.form.setValue(name as any, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
          })
          stepProps.form.trigger("cameras");
        }}
          inputAttrs={stepProps.form.register("cameras." + index as any, {
            validate: {
              min: e => !e || !e.count || e.count >= 0 ? true : i18n.t("form.validation.non-negative"),
              max: e => !e || !e.count || e.count <= 99 ? true : (i18n.t("form.validation.less-than") + " 100")
            }, setValueAs: (v: any) => {
              return { camera: c.camera, count: parseInt(v) };
            }
          })}></NumberField>
      </ConfigItem>
    })}
    <button type="button" style={{ gridColumn: "1 / -1" }} onClick={stepProps.gotoStep(stepProps.stepIndex + 1)}>{i18n.t("cctv.step3.button")}</button>
  </Step>
}

function DataStorageStep(props: { stepProps: StepRenderProps<CctvConfigForm>, options: CctvConfigOptions }) {
  const stepProps = props.stepProps;
  const { i18n } = useTranslation();
  const dataStorageFieldArray = useFieldArray({
    control: stepProps.form.control,
    name: "dataStorage",
    rules: {
      validate: {
        atLeastOneDataStorage: e => e.filter(i => (i.count ?? 0) > 0).length > 0 ? true : i18n.t("cctv.step5.atLeastOne"),
        notMoreThanDataStorage: e => {
          const maxDrives = mapFormToModel(stepProps.form.getValues(), props.options).recorder?.maxDrives ?? 2;
          const selectedDriveCount = e.filter(i => (i.count ?? 0) > 0).map(e => e.count!).reduce((a, b) => a + b, 0);
          return selectedDriveCount > maxDrives ? (i18n.t("cctv.step5.notMoreThan") + maxDrives) : true;
        }
      }
    }
  });
  const totalEstimation = estimateStoredRecordingLength(
    stepProps.form.getValues().cameras,
    stepProps.form.getValues().dataStorage
  )

  return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step5.label")} className={'harddrive-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.errors.dataStorage?.root?.message}>
    {dataStorageFieldArray.fields.map((d, index) => {
      const estimation = estimateStoredRecordingLength(
        stepProps.form.getValues().cameras,
        [{ dataStorage: d.dataStorage, count: 1 }]
      )
      const error = stepProps.form.formState.errors.dataStorage?.[index]?.message;
      return <ConfigItem key={d.id} configElement={d.dataStorage}>
        {error ? <span className='error'>{error}</span> : ""}
        <NumberField setValue={(name, value) => {
          stepProps.form.setValue(name as any, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
          });
          stepProps.form.trigger("dataStorage");
        }}
          inputAttrs={stepProps.form.register("dataStorage." + index as any, {
            validate: {
              min: e => !e.count || e.count >= 0 ? true : i18n.t("form.validation.non-negative"),
              max: e => !e.count || e.count <= 99 ? true : (i18n.t("form.validation.less-than") + " 100")
            }, setValueAs: (v: any) => {
              return { dataStorage: d.dataStorage, count: parseInt(v) };
            }
          })}></NumberField>
        <span style={{ marginBottom: "1em" }}>{i18n.t("cctv.step5.estimateSingle")}: {estimation}</span>
      </ConfigItem>;
    })}
    <span style={{ marginBottom: "1em", gridColumn: "1/-1" }}>{i18n.t("cctv.step5.estimateTotal")}: {totalEstimation ?? 0}</span>
    <button type="button" style={{ gridColumn: "1 / -1" }} onClick={stepProps.gotoStep(stepProps.stepIndex + 1)}>{i18n.t("cctv.step5.button")}</button>
  </Step>
}

export function Cctv() {
  const { i18n } = useTranslation();
  const { options, products } = useLoaderData() as { options: CctvConfigOptions, products: ProductsById };

  const form = useForm<CctvConfigForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      cameras: options.cameras.map(e => ({ camera: e })),
      dataStorage: options.dataStorage.map(e => ({ dataStorage: e })),
    }
  })

  function onSubmit() {
    const model = mapFormToModel(form.getValues(), options);
    console.log(model);
  }

  const [integrationsStep,] = useState<StepNode<CctvConfigForm>>({
    id: 2,
    getStepLabel: () => i18n.t("cctv.step2.label"),
    completed: false,
    completedTest: (form) => !!mapFormToModel(form.getValues(), options).integrations,
    formFields: ["integrations"],
    valid: true,
    renderer: (stepProps: StepRenderProps<CctvConfigForm>) => {
      return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step2.label")} className={'rsmodule-section ' + (stepProps.activeStep === stepProps.stepIndex ? '' : 'hidden')} error={stepProps.form.formState.errors.integrations?.message}>
        <ConfigItem configElement={MORE_THAN_2_INTEGRATIONS}>
          <input type="radio" {...stepProps.form.register("integrations", { required: i18n.t("form.validation.required"), onChange: stepProps.gotoStep(stepProps.stepIndex + 1) })} radioGroup="integrations" defaultValue={MORE_THAN_2_INTEGRATIONS.id} />
        </ConfigItem>
        <ConfigItem configElement={_2_OR_FEWER_INTEGRATIONS}>
          <input type="radio" {...stepProps.form.register("integrations", { required: i18n.t("form.validation.required"), onChange: stepProps.gotoStep(stepProps.stepIndex + 1) })} radioGroup="integrations" defaultValue={_2_OR_FEWER_INTEGRATIONS.id} />
        </ConfigItem>
      </Step>
    }
  });

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    {
      id: 1,
      getStepLabel: () => i18n.t("cctv.step1.label"),
      completed: false,
      completedTest: (form) => !!mapFormToModel(form.getValues(), options).recorder,
      formFields: ["recorder"],
      valid: true,
      visited: true,
      renderer: (stepProps: StepRenderProps<CctvConfigForm>) => {
        return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step1.label")} className={'recorder-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.errors.recorder?.message}>
          <div className='card small glow' style={{ gridColumn: "1 / -1", margin: 0 }}>
            <Title text={"Jak wybrać rejestrator?"}></Title>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum porta vestibulum elit, sit amet accumsan nunc. Vivamus non aliquam lorem. Phasellus dolor neque, semper pulvinar ullamcorper sit amet, ultrices et nibh. Sed ligula nisl, condimentum ut accumsan ut, varius in nunc. Sed quis interdum nunc, ut luctus mi. Quisque lorem neque, sollicitudin eu ornare a, tempor quis quam. Nullam quis nisl sed enim consectetur ullamcorper a et ex. Vivamus ipsum lacus, volutpat in semper at,
          </div>
          <ConfigItem configElement={options.analyticsRecorder}>
            <input type="radio" {...stepProps.form.register("recorder", {
              required: i18n.t("form.validation.required"), onChange: () => {
                const analytics = mapFormToModel(stepProps.form.getValues(), options).recorder?.analytics
                const newCameras = options.cameras
                  .filter(c => c.analytics === analytics)
                  .map(c => ({ camera: c }))
                stepProps.form.setValue("cameras", newCameras);
                const index = stepProps.steps.findIndex((s) => integrationsStep === s)
                if (analytics) {
                  if (index < 0) {
                    stepProps.steps.splice(1, 0, integrationsStep)
                    setSteps([...steps]);
                  }
                } else {
                  if (index >= 0) {
                    stepProps.steps.splice(1, 1)
                    setSteps([...steps]);
                  }
                }
                stepProps.gotoStep(stepProps.stepIndex + 1)();
              }
            })} radioGroup="recorder" defaultValue={options.analyticsRecorder.id} />
          </ConfigItem>
          <ConfigItem configElement={options.noAnalyticsRecorder}>
            <input type="radio" {...stepProps.form.register("recorder", { required: i18n.t("form.validation.required") })} radioGroup="recorder" defaultValue={options.noAnalyticsRecorder.id} />
          </ConfigItem>
        </Step>
      }
    },
    integrationsStep,
    {
      id: 3,
      getStepLabel: () => i18n.t("cctv.step3.label"),
      completed: false,
      completedTest: (form) => !!mapFormToModel(form.getValues(), options).cameras?.length,
      formFields: ["cameras"],
      valid: true,
      renderer: (stepProps: StepRenderProps<CctvConfigForm>) => <CameraStep
        stepProps={stepProps}
        options={options}
      ></CameraStep>
    },
    {
      id: 4,
      getStepLabel: () => i18n.t("cctv.step4.label"),
      completed: false,
      completedTest: (form) => !!mapFormToModel(form.getValues(), options).installationMethod,
      formFields: ["installationMethod"],
      valid: true,
      renderer: (stepProps: StepRenderProps<CctvConfigForm>) => {
        return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step4.label")} className={'installation-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.errors.installationMethod?.message}>
          {options.installationMethod.map(i => {
            return <ConfigItem key={i.id} configElement={i}>
              <input type="radio" {...stepProps.form.register("installationMethod", { required: i18n.t("form.validation.required"), onChange: stepProps.gotoStep(stepProps.stepIndex + 1) })} radioGroup="installationMethod" defaultValue={i.id} />
            </ConfigItem>;
          })}
        </Step>
      }
    },
    {
      id: 5,
      getStepLabel: () => i18n.t("cctv.step5.label"),
      completed: false,
      completedTest: (form) => !!mapFormToModel(form.getValues(), options).dataStorage?.length,
      formFields: ["dataStorage"],
      valid: true,
      renderer: (stepProps: StepRenderProps<CctvConfigForm>) => <DataStorageStep
        stepProps={stepProps}
        options={options}
      ></DataStorageStep>
    },
    {
      id: 6,
      getStepLabel: () => i18n.t("cctv.step6.label"),
      completed: false,
      completedTest: (form) => !!mapFormToModel(form.getValues(), options).installationStorage,
      formFields: ["installationStorage"],
      valid: true,
      renderer: (stepProps: StepRenderProps<CctvConfigForm>) => {
        return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step6.label")} className={'storage-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.errors.installationStorage?.message}>
          {options.installationStorage.map(i => {
            return <ConfigItem key={i.id} configElement={i}>
              <input type="radio" {...stepProps.form.register("installationStorage", { required: i18n.t("form.validation.required"), onChange: stepProps.gotoStep(stepProps.stepIndex + 1) })} radioGroup="installationStorage" defaultValue={i.id} />
            </ConfigItem>;
          })}
        </Step>
      }
    },
    {
      id: 7,
      getStepLabel: () => i18n.t("cctv.step7.label"),
      completed: false,
      completedTest: (form) => !!mapFormToModel(form.getValues(), options).cabling,
      formFields: ["cabling"],
      valid: true,
      renderer: (stepProps: StepRenderProps<CctvConfigForm>) => {
        return <Step title={i18n.t("configurator.step") + " " + (stepProps.stepIndex + 1) + ". " + i18n.t("cctv.step7.label")} className={'cabling-section ' + (stepProps.active ? '' : 'hidden')} error={stepProps.form.formState.errors.cabling?.message}>
          <label>
            <select {...stepProps.form.register("cabling", { validate: { nonEmpty: e => e === Cabling.EMPTY ? i18n.t("form.validation.required") : true }, onChange: stepProps.gotoStep(stepProps.stepIndex + 1) })} style={{ width: "100%" }} defaultValue={Cabling.EMPTY}>
              {
                cablingDict.map(e => <option key={e.key} value={e.key}>{e.value[lang]}</option>)
              }
            </select>
            <span>{i18n.t("cctv.step7.query")}</span>
          </label>
        </Step>
      }
    },
  ] as StepNode<CctvConfigForm>[]);

  const lang = i18n.language as "pl" | "en";

  return <GenericConfigurator
    className='Cctv'
    form={form}
    steps={steps}
    activeStep={activeStep}
    setSteps={setSteps}
    setActiveStep={setActiveStep}
    onSubmit={onSubmit}
    onInvalid={() => { }}
    getSummary={(form) => {
      const model = mapFormToModel(form.getValues(), options);
      const cameraCount = (model.cameras ?? []).map(e => e.count).reduce((acc, x) => acc + x, 0);

      const analytics = model.recorder?.analytics === true;
      const integrations = analytics && model.integrations === MORE_THAN_2_INTEGRATIONS.id;
      const nvrInstallation = products.get(integrations ? NVR_INSTALLATION_INTEGRATION_ID : NVR_INSTALLATION_ID)!;

      const cameraInstallation = products.get(analytics ? CAMERA_INSTALLATION_ANALYTICS_ID : CAMERA_INSTALLATION_ID)!;

      const rackInstallation = products.get(RACK_INSTALLATION_ID)!;

      const switchCount = Math.ceil(cameraCount / (options.switch.poePortsCount ?? 8));

      return <div className='summary-text'>
        <ul>
          {model.recorder ? <li>{i18n.t("cctv.step1.summaryLabel")}: {model.recorder.name[lang]} {model.recorder.computedGross.toFormat()}PLN</li> : ""}
          <li>{nvrInstallation.name[lang]} 1 x {nvrInstallation.computedGross.toFormat()}PLN</li>
          {model.cameras?.length ? <li>{i18n.t("cctv.step3.summaryLabel")}:
            <ul>
              {model.cameras.map(c => {
                return <li key={c.camera.id}>{c.camera.name[lang]} {c.count} x {c.camera.computedGross.toFormat()}PLN</li>;
              })}
            </ul>
          </li> : ""}
          <li>{cameraInstallation.name[lang]} {cameraCount} x {cameraInstallation.computedGross.toFormat()}PLN</li>
          <li>{options.switch.name[lang]} {switchCount} x {options.switch.computedGross.toFormat()}PLN</li>
          {model.installationMethod ? <li>{i18n.t("cctv.step4.summaryLabel")}: {model.installationMethod.name[lang]} {cameraCount} x {model.installationMethod.computedGross.toFormat()}PLN</li> : ""}
          {model.dataStorage?.length ? <li>{i18n.t("cctv.step5.summaryLabel")}:
            <ul>
              {model.dataStorage.map(d => {
                return <li key={d.dataStorage.id}>{d.dataStorage.name[lang]} {d.count} x {d.dataStorage.computedGross.toFormat()}PLN</li>;
              })}
            </ul>
          </li> : ""}
          {/* {model.dataStorage ? <li>Dysk twardy: {model.dataStorage.name[lang]} {model.dataStorage.computedGross.toFormat()}PLN</li> : ""} */}
          {model.installationStorage ? <li>{i18n.t("cctv.step6.summaryLabel")}: {model.installationStorage.name[lang]} {model.installationStorage.computedGross.toFormat()}PLN</li> : ""}
          <li>{rackInstallation.name[lang]} 1 x {rackInstallation.computedGross.toFormat()}PLN</li>
          {model.cabling === Cabling.NO_CABLING ? <li>{i18n.t("cctv.step7.noCabling")}: {i18n.t("configurator.price")}?PLN</li> : ""}
          {model.cabling === Cabling.BAD_CABLING ? <li>{i18n.t("cctv.step7.badCabling")}: {i18n.t("configurator.price")}?PLN</li> : ""}
        </ul>
      </div>
    }}
  ></GenericConfigurator>
}
