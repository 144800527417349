
import { UseFormRegisterReturn } from 'react-hook-form';
import './Switch.css';
import { ChangeEventHandler, CSSProperties } from 'react';

export interface SwitchProps {
  off: string,
  on: string,
  style?: CSSProperties,
  checked?: boolean,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  required?: boolean,
  reactHook?: UseFormRegisterReturn
}

export function Switch(props: SwitchProps) {
  return <label className='Switch' style={props.style}>
    <span>{props.off}</span>
    <input type="checkbox" checked={props.checked} onChange={props.onChange} className='toggle' {...props.reactHook} required={props.required}></input>
    <span>{props.on}</span>
  </label>;
}
