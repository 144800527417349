import parse from 'html-react-parser';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { ConfigElement } from '../../../Configurator/components/products';
import './ConfigItem.css';

export function ConfigItem(props: {
  configElement: ConfigElement,
  children?: React.ReactNode
}) {
  const { i18n } = useTranslation()
  const lang = i18n.language as "pl" | "en"
  return <label className='ConfigItem'>
    {props.children}
    <div className='config-item-header'>
      <span className='config-item-name'>{props.configElement.name[lang]}</span>
      <div className='config-item-pricing'>
        <span>Netto {props.configElement.computedNet.toFormat()} PLN</span>
        <span>Brutto {props.configElement.computedGross.toFormat()} PLN</span>
      </div>
      <div className='config-item-body'>
        <img className='config-item-img' src={props.configElement.img} />
        <div className='config-item-attrs'>
          {props.configElement.attributes?.filter(attr => attr.show !== false).map((attr, i, arr) => {
            return <React.Fragment key={attr.name}>
              <span className='config-item-attr-name'>{attr.name}</span>
              <span className='config-item-attr-value'>{attr.value}</span>
              {i + 1 < arr.length ? <hr /> : ""}
            </React.Fragment>
          })}
        </div>
      </div>
      <span className='config-item-description'>{props.configElement.description ? parse(props.configElement.description[lang]) : ''}</span>
    </div>
  </label>
}