import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Switch } from './components/Form/components/Switch/Switch';
import { Logo } from './components/Logo/Logo';

function App() {
  const { i18n } = useTranslation();
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [theme, setTheme] = useState<"light" | "dark">(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light");
  useEffect(() => {
    document.documentElement.setAttribute("theme", theme)
  })

  const closeNav = () => setNavOpen(false);
  const toggleNav = () => setNavOpen(!navOpen);

  const changeLang: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('pl')
    }
  }

  const changeTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }

  return (
    <div className="App">
      <header>
        <Logo />
        <div className='burger mobile' onClick={toggleNav}></div>
        <nav data-open={navOpen} className='main-menu underline'>
          <NavLink to={'/'} onClick={closeNav}>{i18n.t("menu.start")}</NavLink>
          {/* <NavLink to={'about'} onClick={closeNav}>{i18n.t("menu.about")}</NavLink>
          <NavLink to={'certificates'} onClick={closeNav}>{i18n.t("menu.certificates")}</NavLink> */}
          <NavLink to={'contact'} onClick={closeNav}>{i18n.t("menu.contact")}</NavLink>
          <NavLink to={'configurator'} onClick={closeNav}>{i18n.t("menu.configurator")}</NavLink>
          <div className="mobile" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Switch style={{ marginRight: "1em" }} off="Light" on="Dark" checked={theme === "dark"} onChange={changeTheme}></Switch>
            <Switch off="PL" on="EN" checked={i18n.language === "en"} onChange={changeLang}></Switch>
          </div>
        </nav>
        <div className="desktop" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Switch style={{ marginRight: "1em" }} off="Light" on="Dark" checked={theme === "dark"} onChange={changeTheme}></Switch>
          <Switch off="PL" on="EN" checked={i18n.language === "en"} onChange={changeLang}></Switch>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
        transition={Slide}
      />
    </div>
  );
}

export default App;
