
import './NumberField.css';

function getNumberAttr(input: HTMLInputElement, attrName: keyof React.InputHTMLAttributes<HTMLInputElement>, def: number) {
  const attrVal = (input as any)[attrName];
  if (attrVal) {
    const attrNumVal = parseFloat(attrVal)
    if (isNaN(attrNumVal)) {
      return def;
    } else {
      return attrNumVal;
    }
  } else {
    return def;
  }
}

function increment(
  target: EventTarget,
  step: number,
  setValue?: (fieldName: string, newValue: number) => void
) {
  const input: HTMLInputElement = (target as HTMLElement).parentElement!.firstChild as HTMLInputElement;
  const value = getNumberAttr(input, "value", 0)
  const min = getNumberAttr(input, "min", -Infinity)
  const max = getNumberAttr(input, "max", Infinity)

  const newValue = value + step;
  const newValueFinal = newValue < min ? input.value = min + ""
    : newValue > max ? input.value = max + ""
      : input.value = newValue + "";

  if (setValue) {
    setValue(input.name, parseInt(newValueFinal));
  } else {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value')?.set as any;
    nativeInputValueSetter.call(input, newValueFinal);
    input.dispatchEvent(new Event("change", { bubbles: true }));
  }
}

export function NumberField(props: {
  setValue?: (fieldName: string, newValue: number) => void,
  rootAttrs?: React.HTMLAttributes<HTMLDivElement>,
  inputAttrs?: React.InputHTMLAttributes<HTMLInputElement>
} = {}) {
  const defaultValue = props.inputAttrs?.defaultValue ?? 0;
  return <div className="NumberField" {...props.rootAttrs}>
    <input type="number" {...props.inputAttrs} defaultValue={defaultValue} />
    <button onClick={e => increment(e.target, -1, props.setValue)} type="button">-</button>
    <button onClick={e => increment(e.target, +1, props.setValue)} type="button">+</button>
  </div>;
}
