import BigNumber from "bignumber.js";

export interface TranslatedContent {
  pl: string,
  en: string
}

export interface ConfigElement {
  name: TranslatedContent,
  img?: string,
  description?: TranslatedContent,
  attributes?: { name: string, value: string, show?: boolean }[],
  id: number,
  priceNet: number,
  priceGross: number,
  autoUpdate?: boolean,
  applyMargin?: boolean,
  requires?: number[],
  computedNet: BigNumber,
  computedGross: BigNumber,
}

export type Camera = ConfigElement & {
  pixelsPerFrame: number,
  analytics: boolean,
  // motoZoom: boolean,
  // color: "white" | "black"
}

export type Recorder = ConfigElement & {
  analytics: boolean,
  maxCameras: number,
  maxCapacityBytes: number,
  maxDrives: number
};

export type DataStorage = (ConfigElement & {
  capacityBytes: number
})

export type PoeSwitch = (ConfigElement & {
  poePortsCount: number
})

export type AnyProduct = ConfigElement | Camera | Recorder | DataStorage;

type Prices = { priceNet: BigNumber, priceGross: BigNumber };
const ZERO: Prices = { priceNet: new BigNumber(0), priceGross: new BigNumber(0) };

function getPrices(configElement: ConfigElement, products: ProductsById, visited: number[] = []): Prices {
  if (visited.indexOf(configElement.id) >= 0) {
    return ZERO;
  }
  visited.push(configElement.id);

  const margin = new BigNumber((configElement.applyMargin ?? true) ? 1.15 : 1);

  const priceNet = (new BigNumber(configElement.priceNet)).multipliedBy(margin);
  const priceGross = (new BigNumber(configElement.priceGross)).multipliedBy(margin);

  const otherPrices = configElement.requires
    ?.map(id => products.get(id))
    .filter(e => !!e)
    .map(p => getPrices(p!, products, visited))
    .reduce((a, b) => ({
      priceNet: a.priceNet.plus(b.priceNet),
      priceGross: a.priceGross.plus(b.priceGross),
    }), ZERO) ?? ZERO;

  return {
    priceNet: new BigNumber(priceNet.plus(otherPrices.priceNet).toFixed(2, BigNumber.ROUND_HALF_UP)),
    priceGross: new BigNumber(priceGross.plus(otherPrices.priceGross).toFixed(2, BigNumber.ROUND_HALF_UP))
  };
}

export type ProductsById = Map<number, AnyProduct>

export function productsById(productsJson: string) {
  const byId = new Map((JSON.parse(productsJson) as AnyProduct[]).map(p => [p.id, p]));
  for (const p of byId.values()) {
    const prices = getPrices(p, byId);
    p.computedNet = prices.priceNet;
    p.computedGross = prices.priceGross;
  }
  return byId;
}

export function createOptions(optionsJson: string, productsJson: string) {
  const options = JSON.parse(optionsJson)
  const byId = productsById(productsJson);

  return traverse(options, byId);
}

function traverse(options: any, products: Map<number, ConfigElement>) {
  const mergedOptions: any = {};
  for (let key in options) {
    const type = typeof options[key];
    if (type === "number") {
      mergedOptions[key] = products.get(options[key]);
    } else if (options[key] instanceof Array) {
      const arr = options[key]
      mergedOptions[key] = arr.map((id: number) => products.get(id));
    } else if (type === "object") {
      mergedOptions[key] = traverse(options[key], products)
    }
  }
  return mergedOptions
}

export const NVR_INSTALLATION_INTEGRATION_ID = -1;
export const NVR_INSTALLATION_ID = -2;
export const CAMERA_INSTALLATION_ANALYTICS_ID = -3;
export const CAMERA_INSTALLATION_ID = -4;
export const RACK_INSTALLATION_ID = -5;